<template>
    <div class="zonas-comunes-reservas-listado overflow-auto custom-scroll h-100 w-100" style="height: calc(100vh - 400px)">
        <div class="row d-flex mx-2">
            <div class="col-auto ml-auto">
                <el-input v-model="search" placeholder="Buscar por nombre del solicitante" prefix-icon="el-icon-search" style="width:260px" size="small" @change="listar" />
            </div>
        </div>
        <div class="col-12 f-17 h-100">
            <el-table :data="reservas" height="calc(100vh - 296px)" @row-click="verReserva">
                <el-table-column sortable prop="zona" min-width="160" label="Zona">
                    <template slot-scope="scope">
                        <div class="d-flex tres-puntos">
                            <p class="f-16 ml-2 my-auto tres-puntos"> {{ scope.row.zona.nombre }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="usuario" min-width="190" label="Solicitante">
                    <template slot-scope="scope">
                        <div class="d-flex tres-puntos">
                            <div class="wh-32px rounded-circle d-middle-center bg-azulclaro">
                                <i class="icon-account-outline f-20" />
                            </div>
                            <p class="f-16 ml-2 my-auto tres-puntos"> {{ scope.row.usuario.nombre }} {{ scope.row.usuario.apellido }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="usuario" min-width="180" label="Vivienda">
                    <template slot-scope="scope">
                        <div class="d-flex tres-puntos">
                            <p class="f-16 ml-2 my-auto tres-puntos"> {{ scope.row.usuario.vivienda }} </p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="fecha" min-width="180" label="Fecha programado">
                    <template slot-scope="scope">
                        <p>{{ scope.row.fecha | helper-fecha('DD MMM YYYY') }}</p>
                    </template>
                </el-table-column>
                <el-table-column sortable prop="hora_inicio" min-width="180" label="Horario">
                    <template slot-scope="scope">
                        <p> {{ formatearHora(scope.row.hora_inicio) }} - {{ formatearHora(scope.row.hora_fin) }} </p>
                    </template>
                </el-table-column>
                <div slot="empty" class="row">
                    <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                    </div>
                </div>
            </el-table>
        </div>
        <infinite-loading @infinite="listar">
            <div slot="spinner" class="mt-4">
                Consultando...
            </div>
            <div slot="no-more" class="mt-4">
                No hay más reservas
            </div>
            <div slot="no-results" />
        </infinite-loading>
    </div>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading';
import Reservas from '~/services/reservas'
export default {
    components:{
        InfiniteLoading
    },
    data(){
        return{
            loading: false,
            page: 1,
            reservas: [],
            mostrarRequierenPago: false,
            search: ''
        }
    },
    watch:{
        search(){
            this.page = 1
            this.reservas = []
        },
        mostrarRequierenPago(){
            this.page = 1
            this.reservas = []
            this.listar()
        }
    },
    methods:{
        verReserva(row){
            this.$router.push({ name:'zonas.reservas.ver', params:{id_reserva : row.id} })
        },
        async listar($state=false){
            try {

                const state = $state
            
                let params= {
                    page: this.page,
                    search: this.search,
                    estados_verificar: 1,
                    pago_requerido: this.mostrarRequierenPago ? 1 : 0
                }

                const {data} = await Reservas.listar(params)
            
                if(data.data.data.length){
                    this.page++
                    this.reservas.push(...data.data.data)
                    if(state)state.loaded();
                }else{
                    if(state)state.complete();
                }
            } catch (e){
                this.errorCatch(e)
            }
        },
    }
}
</script>
<style lang="scss" scoped>

</style>